a {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:focus {
  text-decoration: none;
}
a:hover,
a:active {
  text-decoration: none;
}
p {
  margin: 0;
  padding: 0;
}
body {
  width: 100%;
  height: auto;
}
.home-container {
  background-image: url("../images/lol-map.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.home-header {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 8px 6px -6px rgb(32, 32, 32);
  margin-bottom: 50px;
}
nav {
  justify-content: right;
  flex-direction: row-reverse;
  width: 20%;
}
/* need a responsive solution */
nav img {
  max-width: 100%;
  height: auto;
  min-height: 60px;
  max-width: 60px;
}
.nav-links {
  display: flex;
  padding-top: 10px;
  font-size: 18px;
  margin-top: 0.7rem;
}
.nav-links a {
  margin-right: 1rem;
  color: black;
}
.nav-links a:hover {
  color: rgb(167, 162, 162);
}
.nav-links ul {
  justify-content: space-between;
}
.home-items-container {
  display: flex;
  font-size: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  height: 100%;
}
.home-items-container div {
  font-weight: bolder;
  cursor: pointer;
  color: white;
  margin-bottom: 10rem;
  justify-content: center;
  align-items: center;
}
.sign-up-button {
  height: 30px;
  width: 100%;
}
/* need responsive solution  */

/* Top Css */
.signup-container {
  display: flex;
}
.home-header .home-container .sign-up-link {
  color: white;
  margin-top: 1rem;
}
.dashboard-link{
  margin-right: 10px;
}

@media (max-width: 400px) {
  .nav-links {
    font-size: 3.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }
}