body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}
h2 {
  font-weight: normal;
}
.discussion-container {
  /* position: absolute; */
  /* width: 100vw; */
  /* height: 100vh; */
  /* background-position: top center; */
  /* background-repeat: no-repeat center center fixed; */
  background-image: url("../images/backdrop.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}

/* page title */
.discussion-headers {
  text-align: center;
  font-size: 36px;
  font-weight: bolder;
  color: white;
  margin-bottom: 2rem;
}

/* actual post text and delete/update buttons */
.discussion-post-container {
  display: flex;
  justify-content: center;
}

@media (min-width: 750px){
  .post-contents {
    margin-bottom: 5%;
    background-color: #dbdbdb;
    padding: 3%;
    border-radius: 25px;
    word-break: break-word;
    min-width: 800px;
    max-width: 800px;
  }
}

@media (max-width: 749px) { 
  .post-contents {
    margin-bottom: 5%;
    background-color: #dbdbdb;
    padding: 3%;
    border-radius: 25px;
    word-break: break-word;
    max-width: 350px;
    min-width: 350px;
  }
 }

 @media (max-width: 400px) {
  .post-contents {
    margin-bottom: 5%;
    background-color: #dbdbdb;
    padding: 3%;
    border-radius: 25px;
    word-break: break-word;
    max-width: 300px;
    min-width: 300px;
  }
  .post-contents h1{
    font-size: 8vw;
  }
  .post-contents h2{
    font-size: 6vw;
  }
  .post-contents h3{
    font-size: 5vw;
  }
  .post-contents h4{
    font-size: 4vw;
  }
  .post-contents h5{
    font-size: 3vw;
  }
}
.post-contents{
  border: 3px solid #4a473c;
}
.post-contents button {
  border-radius: 50px;
  margin-right: 10px;
  margin-top: 1rem;
}
.post-contents img {
  max-width: 200px;
  max-height: 200px;
  margin-top: 10px;
  border-radius: 10px;
}
/* input fields and add post button */
.post-creation-items {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2px;
}
.post-creation-items input {
  border-radius: 5px;
}
.post-creation-items textarea {
  min-height: 100px;
  margin-top: 10px;
  border-radius: 5px;
}
.post-creation-items button {
  margin-bottom: 5%;
  border-radius: 50px;
  margin-top: 10px;
}

.post-buttons {
  display: flex;
  flex-direction: column;
}
.post-buttons input,
textarea {
  margin-top: 20px;
  border-radius: 2px;
}

/* #post-button {
  background: white;
} */
