.thread-container {
  background-repeat: no-repeat;
  background-image: url("../images/backdrop.jpg");
  background-size: cover;
  min-height: 100vh;
}
.thread-items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.thread-items-container ul {
  font-size: 48px;
  display: flex;
  flex-direction: column;
}
/* @media (max-width: 500px) {
  .thread-items-container{
    max-width: 410px;
  }
} */
.thread-items-container li {
  margin-bottom: 10%;
  text-decoration: none;
  list-style-type: none;
  background-color: #dbdbdb;
  text-align: center;
  border-radius: 15px;
  padding: 5%;
  border: 3px solid #7e651a;
}
.thread-items-container a {
  color: black;
}
.thread-items-container a:hover {
  color: rgb(39, 39, 39);
  opacity: .9;
}

@media (max-width: 500px) {
  .thread-items-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  .thread-items-container ul {
    font-size: 32px;
    display: flex;
    width: 70%;
    margin: 0 auto;
    flex-direction: column;
  }
  .thread-items-container ul li {
    font-size: 6vw;
  }
}