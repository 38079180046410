.photo-selector-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.photo-selector-container label {
  margin-top: 10px;
  border: 1px solid black;
    padding: 10px;
    background-color: #337ab7;
    border-radius: 5px;
    color: white;
    width: 100%;
  }
  .photo-selector-container label:hover{
    opacity: .9;
    color: black;
  }
